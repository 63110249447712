import { Button, Card, CardContent, CardHeader, Grid, InputAdornment, Snackbar, TextField, Typography } from "@material-ui/core";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Alert, AlertTitle } from '@material-ui/lab';
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { QRCode } from 'react-qrcode-logo';
import toastr from "toastr";
import "toastr/build/toastr.css";
import $ from "jquery";
import Timer from "../timer";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Swal from "sweetalert2";
const TronGrid = require('trongrid');
const TronWeb = require('tronweb');
var sourceAddress = "";
var currency = "";
let orgAmount = 0;
let transInfo = {};
var ackId = '';
var successUrl = "";
var failureUrl = "";
var transactionCharge = 0;
require("dotenv").config();
const mode = process.env.REACT_APP_MODE;
let curScriptId = "Waiting For Payment"
const explorerUrl = mode === "test" ? "https://tronscan.org" : "https://shasta.tronscan.org"
var currentAddressBalance = "";
var istronWeb = false;
var currentaddress = "";

export default function TronPayment(props) {
    const { tokenInfo, dataInfo } = props;
    const [open, setOpen] = React.useState(true);
    const [exchange, setExchange] = React.useState('');
    const handleChange = (event) => {
        setExchange(event.target.value);
    };
    let seconds = 0;
    try {
        transInfo = jwt_decode(tokenInfo);
        let curAddress = jwt_decode(dataInfo);
        sourceAddress = curAddress.address;
        successUrl = transInfo.successUrl;
        failureUrl = transInfo.failureUrl;
        currency = transInfo.type;
        transactionCharge = curAddress.transactionCharge;
        orgAmount = parseFloat(curAddress.coins).toFixed(6);
        ackId = curAddress.ackId;
        const stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
        const endTime = transInfo.exp; // use UNIX timestamp in seconds
        seconds = endTime - stratTime;
    }
    catch (e) {
        console.log(e);
    }
    const [transactionId, setTransactionId] = useState("Waiting For Payment");
    const [status, setStatus] = useState("Waiting For Payment");
    const [amount, setAmount] = useState(0);
    const [timeLeft, setTimeLeft] = useState(seconds);

    useEffect(() => {
        if (exchange === "Binance Wallet") {
            setAmount((parseFloat(orgAmount) + 1).toFixed(6));
        }
        else {
            setAmount(parseFloat(orgAmount).toFixed(6));
        }

    }, [exchange])

    useEffect(() => {
        if (amount !== 0 && currency.toLowerCase() === "trx") {
            log("Transaction linked with block chain. Please make payment soon");
            const tronWeb = new TronWeb({
                fullHost: mode === "test" ? 'https://api.shasta.trongrid.io' : 'https://api.trongrid.io'
            });
            log("We are waiting for your payment");
            const tronGrid = new TronGrid(tronWeb);
            tronGrid.setExperimental("3b913ed3-d308-437d-b940-83f1e4958d02");

            let counter = 1;

            let cnt = setInterval(async () => {
                counter++;
                if (counter === 28) {
                    clearInterval(cnt);
                }
                await getTransactions();
                log("We are waiting for your payment");
            }, 20000)

            async function getTransactions() {
                const address = sourceAddress;

                const options = {
                    onlyTo: true,
                    limit: 10,
                    orderBy: 'timestamp,asc',
                    minBlockTimestamp: Date.now() - 60000 // from a minute ago to go on
                };

                // promise
                tronGrid.account.getTransactions(address, options).then(transactions => {
                    transactions.data.forEach((tran) => {
                        tran.raw_data.contract.forEach((ct) => {
                            if ((ct.parameter.value.amount / 1000000).toFixed(6) === parseFloat(orgAmount).toFixed(6)) {
                                clearInterval(cnt);
                                log("We got some transaction event. We are verifying.");
                                curScriptId = tran.txID;
                                setTransactionId(tran.txID);

                                fetch(process.env.REACT_APP_API_URL + ackId + '?hash=' + tran.txID, {
                                    method: 'PUT',
                                    headers: {
                                        'Authorization': 'Bearer ' + tokenInfo
                                    }
                                }).then(() => {
                                    setTimeout(() => {
                                        var form = $('<form action="' + successUrl + '" method="post">' +
                                            '<input type="text" name="token" value="' + tokenInfo + '" />' +
                                            '<input type="text" name="transactionHash" value="' + tran.txID + '" />' +
                                            '<input type="text" name="transactionId" value="' + transInfo.txnid + '" />' +
                                            '<input type="text" name="referenceNo" value="' + ackId + '" />' +
                                            '</form>');
                                        $('body').append(form);
                                        form.submit();
                                    }, 5000)
                                }).catch((err) => {
                                    toastr.error("We received your payment.but we are unabe process request now", "Update Status");
                                    console.log(err);
                                    setTimeout(() => {
                                        var form = $('<form action="' + failureUrl + '" method="post">' +
                                            '<input type="text" name="token" value="' + tokenInfo + '" />' +
                                            '<input type="text" name="transactionHash" value="' + tran.txID + '" />' +
                                            '<input type="text" name="transactionId" value="' + transInfo.txnid + '" />' +
                                            '<input type="text" name="referenceNo" value="' + ackId + '" />' +
                                            '</form>');
                                        $('body').append(form);
                                        form.submit();
                                    }, 5000);
                                }).finally(() => {
                                    setTransactionId(tran.txID);
                                })

                                toastr.success("Transaction Initiated Waiting for the Block Chain to Confirm");
                                setStatus("Transaction Initiated Waiting for the Block Chain to Confirm");
                                return;
                            }
                        })
                    })
                }).catch(err => console.error(err));
            }


        }
    }, [amount]);


    if (transactionId === "Waiting For Payment" && timeLeft <= 0) {
        return <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            Session Expired — <strong>check it out!</strong>
        </Alert>;
    }

    if (transactionId !== "Waiting For Payment") {
        return <Alert severity="info">
            <AlertTitle>Payment Success</AlertTitle>
            Your Transaction is Confirmed with transaction hash&nbsp;&nbsp;&nbsp;
            <a style={{ color: "white", wordBreak: "break-word" }}
                href={explorerUrl + "/#/transaction/" + transactionId}>{transactionId}</a>
            <br /><strong>For more information&nbsp;&nbsp;&nbsp;
                <a style={{ color: "white" }}
                    href={explorerUrl + "/#/transaction/" + transactionId}>Click Here</a>
            </strong>
        </Alert>;
    }


    function log(msg) {
        console.log(msg);
        var node = document.createElement("LI");
        if (node) {             // Create a <li> node
            var textnode = document.createTextNode(msg);
            if (node && node.appendChild) {    // Create a text node
                node.appendChild(textnode);
            }
            if (document.getElementById("log")) {
                document.getElementById("log").appendChild(node);
            }
        }
    }

    function copyText(id) {
        var copyText = document.getElementById(id);

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        document.execCommand("copy");
        if (id === "myInput") {
            toastr.success("Address Copied to clipboard");
        }
        else {
            toastr.success("Amount Copied to clipboard");
        }
    }

    return (
        <Grid container spacing={1}>
            <Grid item md={6} sm={12} xs={12} style={{ "textAlign": "center" }}>
                <Card elevation={10}>
                    <CardHeader title={"Coins : " + amount + " " + currency}
                        subheader={"Transaction Status : " + transactionId} />
                    <CardContent>


                        {
                            timeLeft > 300 ?
                                <>
                                    <QRCode value={sourceAddress} />
                                    <br />
                                    <br />
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        value={sourceAddress}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <FileCopyIcon htmlColor="#b27919" onClick={() => copyText("myInput")} />
                                                </InputAdornment>
                                            ),
                                        }}
                                        inputProps={{
                                            "aria-readonly": true,
                                            id: "myInput"
                                        }} />
                                    <br />
                                    <br />
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        value={amount}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    TRX
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <FileCopyIcon htmlColor="#b27919" onClick={() => copyText("amtInput")} />
                                                </InputAdornment>
                                            ),
                                        }}
                                        inputProps={{
                                            "aria-readonly": true,
                                            id: "amtInput"
                                        }} />
                                    <Snackbar open={true}>
                                        <Alert severity="error">
                                            Please pay exactly {amount} TRX. Else your transaction will be errored out.
                                            Please do not press back or refresh the screen until the transaction is completed.
                                        </Alert>
                                    </Snackbar>
                                    {
                                        exchange === "Others" ?

                                            <>
                                                <br />
                                                <br />
                                                <Typography variant="h4" component="p">
                                                    Or
                                                </Typography>
                                                <br />
                                                <br />
                                                <div>
                                                    <a
                                                        target="_blank"
                                                        className="btn"
                                                        href={`https://link.trustwallet.com/send?coin=195&address=${sourceAddress}&amount=${amount}`}>
                                                        Pay Using Trust Wallet
                                                    </a>
                                                    {
                                                        istronWeb && <a
                                                            onClick={() => {
                                                                window.tronWeb.trx.sendTransaction(sourceAddress, amount * 1000000);
                                                            }}
                                                            className="btn"
                                                            href="#">
                                                            Pay Using DApp
                                                        </a>
                                                    }

                                                </div>
                                            </> : <></>
                                    }

                                </> :
                                <>
                                    <Alert severity="warning" style={{ textAlign: "left" }}>
                                        <AlertTitle>Transaction Status</AlertTitle>
                                        If Payment is already done by you, <strong>Please wait for some time for confirmations.</strong>
                                    </Alert>
                                    <br />
                                    <br />
                                    <Alert severity="error" style={{ textAlign: "left" }}>
                                        <AlertTitle>Skip Payment</AlertTitle>
                                        <a href={failureUrl}>Click Here</a><strong> to leave the page without payment.</strong>
                                    </Alert>
                                </>
                        }

                        {
                            status === "Transaction Confirmed By Block Chain" ?
                                <Alert severity="success">
                                    <AlertTitle>Success</AlertTitle>
                                    Transaction Confirmed — <strong>check it out!</strong>
                                </Alert>
                                :
                                <div>
                                    <br />
                                    <br />
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        startIcon={<AutorenewIcon />}
                                    >
                                        {status}
                                    </Button>
                                    <br />
                                    <br />
                                    <Button
                                        fullWidth
                                        onClick={() => setOpen(true)}
                                        variant="outlined"
                                        color="primary"
                                    >
                                        Change Exchange
                                    </Button>
                                </div>
                        }

                    </CardContent>
                </Card>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
                <Card elevation={10}>
                    <CardContent>
                        <ul className="details">
                            <li><label>Name: </label><b>{transInfo.name}</b></li>
                            <li><label>Deposit Amount:  </label><b>{(parseFloat(amount) - parseFloat(transactionCharge)).toFixed(6)} {currency.toUpperCase()}</b></li>
                            <li><label>Transaction Charge :  </label><b>{parseFloat(transactionCharge).toFixed(6)} {currency.toUpperCase()}</b></li>
                            <li><label>Customer ID:  </label><b>{transInfo.cid}</b></li>
                            <li><label>Email:  </label><b>{transInfo.email}</b></li>
                            <li><label>Mobile No: </label><b>{transInfo.mobile}</b></li>
                            <li><label>Reference No: </label><b>{transInfo.txnid}</b></li>
                        </ul>
                        {
                            status === "Waiting For Payment" ?
                                <Timer duration={seconds} onComplete={(duration) => {
                                    setTimeLeft(duration);
                                }} /> : <span />
                        }
                    </CardContent>
                </Card>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
                <ul id="log">
                </ul>
            </Grid>
            <Dialog
                maxWidth="sm"
                fullWidth
                open={open}
                BackdropProps={{
                    style: {
                        background: "#000"
                    }
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Select Exchange</DialogTitle>
                <DialogContent>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="gender" name="gender1" value={exchange} onChange={handleChange}>
                            <FormControlLabel value="Connect" control={<Radio />} label="Connect Wallet" />
                            <FormControlLabel value="Binance Wallet" control={<Radio />} label="Binance Exchange" />
                            <FormControlLabel value="Others" control={<Radio />} label="Trust Wallet" />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button href={failureUrl} variant="contained" color="secondary" onClick={() => {

                    }}>
                        Cancel
                    </Button>
                    <Button
                        disabled={exchange === ""}
                        onClick={() => {
                            if (exchange !== "") {
                                setOpen(false);
                            }

                            async function ConnectToWallet() {
                                const interv = setInterval(async () => {
                                    if (window.tronWeb) {
                                        clearInterval(interv);
                                        const tronWeb = window.tronWeb;
                                        log("tronWeb : ", tronWeb);
                                        log("tronweb is successfully fetched from window");

                                        try {
                                            currentaddress = await window.tronWeb.address.fromHex((await window.tronWeb.trx.getAccount()).address.toString());

                                            istronWeb = true;

                                            currentAddressBalance = await tronWeb.trx.getBalance(currentaddress);
                                            currentAddressBalance = currentAddressBalance / (10 ** 6);
                                            log("Address :" + currentaddress);
                                            log("currentAddressBalance :" + currentaddress);
                                            log("istronWeb :" + currentaddress);
                                            Swal.fire({
                                                title: 'Proceed to Pay',
                                                html: `<div style="font-family:'Montserrat';font-size:14px">
                                            <p>Source Address : <b>${currentaddress}</b></p>
                                            <p>Destination Address : <b>${sourceAddress}</b></p>
                                            <p>Your Balance :  <b>${currentAddressBalance} TRX</b></p>
                                            <p>Payable Amount :  <b>${amount} TRX ${parseInt(currentAddressBalance) < parseInt(amount) ? "<b style='color:red'>InSufficient Funds</b>" : ""}</b></p>
                                            </div>`,
                                                confirmButtonText: "Please Proceed",
                                                showCancelButton: true,
                                                cancelButtonText: "Cancel",

                                            }).then((x) => {
                                                if (x.isConfirmed) {
                                                    if (parseInt(currentAddressBalance) > parseInt(amount)) {
                                                        window.tronWeb.trx.sendTransaction(sourceAddress, amount * 1000000);
                                                    }
                                                    else {
                                                        Swal.fire({
                                                            icon: 'error',
                                                            title: 'Insufficient Fund',
                                                            text: "Please make sure you have atleast " + amount + " TRX"
                                                        })
                                                    }
                                                }
                                            });
                                        } catch (e) {
                                            log(e);
                                            log("Tronweb not defined");
                                            Swal.fire({
                                                icon: 'error',
                                                title: 'Insufficient Fund',
                                                text: "Please make sure you have atleast " + amount + " TRX"
                                            })
                                            istronWeb = false;
                                        }
                                    }

                                }, 1000)

                            }

                            if (exchange === "Connect") {
                                ConnectToWallet();
                            }
                        }}
                        variant={exchange === "" ? "outlined" : "contained"}
                        color="primary"
                        autoFocus>
                        Proceed
                    </Button>
                </DialogActions>
            </Dialog>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br /><br />
            <br />
            <ul id="log">

            </ul>
        </Grid>
    );
}

