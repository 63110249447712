import { Button, Card, CardContent, CardHeader, Grid, InputAdornment, Snackbar, TextField, Typography } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Alert, AlertTitle } from '@material-ui/lab';
import $ from "jquery";
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { QRCode } from 'react-qrcode-logo';
import Swal from "sweetalert2";
import toastr from "toastr";
import "toastr/build/toastr.css";
import { firestore } from "../../firebase";
import { useFirestoreQuery } from "../hooks";
import Timer from "../timer";

var sourceAddress = "";
var currency = "";
let orgAmount = 0;
let transInfo = {};
var ackId = '';
var successUrl = "";
var failureUrl = "";
var transactionCharge = 0;
var tokenAddress = "";
var tokenDecimals = "";
var tokenName = "";
var tokenSymbol = "";
var isBuy = false;

require("dotenv").config();
const mode = process.env.REACT_APP_MODE;
const explorerUrl = mode === "test" ? "https://bscscan.org" : "https://shasta.tronscan.org"

export default function BNBPayment(props) {
    const { tokenInfo, dataInfo } = props;

    const db = firestore;
    const [open, setOpen] = React.useState(true);
    const [exchange, setExchange] = React.useState('');
    let seconds = 0;
    try {
        transInfo = jwt_decode(tokenInfo);
        let curAddress = jwt_decode(dataInfo);
        sourceAddress = curAddress.address;
        successUrl = transInfo.successUrl;
        failureUrl = transInfo.failureUrl;
        currency = transInfo.type;
        transactionCharge = curAddress.transactionCharge;
        tokenAddress = transInfo.tokenAddress;
        ackId = curAddress.ackId;
        tokenDecimals = curAddress.tokenDecimals;
        tokenName = curAddress.tokenName;
        tokenSymbol = curAddress.tokenSymbol;
        isBuy = transInfo.isBuySell === "Buy" ? true : false;
        if (isBuy) {
            tokenAddress = "";
            tokenDecimals = "";
            tokenSymbol = "";
            tokenName = "";
        }
        orgAmount = parseFloat(curAddress.coins).toFixed(tokenAddress ? 4 : 8);
        const stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
        const endTime = transInfo.exp; // use UNIX timestamp in seconds
        seconds = endTime - stratTime;
    }
    catch (e) {
        console.log(e);
    }

    const messages = useFirestoreQuery(
        db.collection("deposits")
            .where("txnid", "==", transInfo.txnid)
    );

    const handleChange = (event) => {
        setExchange(event.target.value);
    };

    useEffect(() => {
        console.log(messages);
        if (messages.length > 0) {
            if (messages[0].status === "Approved") {
                Swal.fire({
                    icon: 'success',
                    title: 'Transaction Success',
                    text: "Your Transaction is successful",
                    showConfirmButton: true,
                    showCancelButton: false,
                    timer: 3000
                }).then((x) => {
                    if (x.isConfirmed || x.isDismissed) {
                        document.location.href = successUrl;
                    }
                })
            }
        }
    }, [messages])



    const [transactionId, setTransactionId] = useState("Waiting For Payment");
    const [status, setStatus] = useState("Waiting For Payment");
    const [amount, setAmount] = useState(0);
    const [timeLeft, setTimeLeft] = useState(seconds);

    useEffect(() => {
        if (tokenAddress) {
            setOpen(false);
            setExchange("Others");
        }
    }, [tokenAddress])

    useEffect(() => {
        if (exchange === "Binance Wallet") {
            setAmount((parseFloat(orgAmount) + 0.0005).toFixed(tokenAddress ? 4 : 8));
        }
        else {
            setAmount(parseFloat(orgAmount).toFixed(tokenAddress ? 4 : 8));
        }
    }, [exchange])

    if (transactionId === "Waiting For Payment" && timeLeft <= 0) {
        var form = $('<form action="' + failureUrl + '" method="post">' +
            '<input type="text" name="token" value="' + tokenInfo + '" />' +
            '<input type="text" name="transactionId" value="' + transInfo.txnid + '" />' +
            '<input type="text" name="referenceNo" value="' + ackId + '" />' +
            '</form>');
        $('body').append(form);
        form.submit();
        return <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            Session Expired — <strong>check it out!</strong>
        </Alert>;
    }

    if (transactionId !== "Waiting For Payment") {
        return <Alert severity="success">
            <AlertTitle>Payment Success</AlertTitle>
            Your Transaction is Confirmed with transaction hash&nbsp;&nbsp;&nbsp;
            <a style={{ color: "white", wordBreak: "break-word" }}
                href={explorerUrl + "/#/transaction/" + transactionId}>{transactionId}</a>
            <br /><strong>For more information&nbsp;&nbsp;&nbsp;
                <a style={{ color: "white" }}
                    href={explorerUrl + "/#/transaction/" + transactionId}>Click Here</a>
            </strong>
        </Alert>;
    }

    function log(msg) {
        console.log(msg);
        var node = document.createElement("LI");
        if (node) {             // Create a <li> node
            var textnode = document.createTextNode(msg);
            if (node && node.appendChild) {    // Create a text node
                node.appendChild(textnode);
            }
            if (document.getElementById("log")) {
                document.getElementById("log").appendChild(node);
            }
        }
    }

    function copyText(id) {
        var copyText = document.getElementById(id);

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        document.execCommand("copy");
        if (id === "myInput") {
            toastr.success("Address Copied to clipboard");
        }
        else {
            toastr.success("Amount Copied to clipboard");
        }

    }

    return (
        <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12} style={{ "textAlign": "center" }}>
                <Card elevation={10}>
                    <CardHeader title={"Coins : " + amount + " " + (tokenAddress ? tokenSymbol : "BNB")}
                        subheader={"Transaction Status : " + transactionId} />
                    <CardContent>
                        {
                            timeLeft > 30 ?
                                <>
                                    <QRCode value={sourceAddress} />
                                    <br />
                                    <br />
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        value={sourceAddress}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <FileCopyIcon htmlColor="#b27919" onClick={() => copyText("myInput")} />
                                                </InputAdornment>
                                            ),
                                        }}
                                        inputProps={{
                                            "aria-readonly": true,
                                            id: "myInput"
                                        }} />
                                    <br />
                                    <br />
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        value={amount}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {tokenAddress ? tokenSymbol : "BNB"}
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <FileCopyIcon htmlColor="#b27919" onClick={() => copyText("amtInput")} />
                                                </InputAdornment>
                                            ),
                                        }}
                                        inputProps={{
                                            "aria-readonly": true,
                                            id: "amtInput"
                                        }} />
                                    <Snackbar open={true}>
                                        <Alert severity="warning">
                                            Please pay exactly {amount} {tokenAddress ? tokenSymbol : "BNB"}. Else your transaction will be errored out.
                                        </Alert>
                                    </Snackbar>
                                    {
                                        exchange === "Others" ?

                                            <>
                                                <br />
                                                <br />
                                                <Typography variant="h4" component="p">
                                                    Or
                                                </Typography>
                                                <br />
                                                <br />
                                                <div>
                                                    <a
                                                        target="_blank"
                                                        className="btn"
                                                        href={tokenAddress ?
                                                            `https://link.trustwallet.com/send?coin=20000714&address=${sourceAddress}&amount=${amount}&token_id=${tokenAddress}` :
                                                            `https://link.trustwallet.com/send?coin=20000714&address=${sourceAddress}&amount=${amount}`}>
                                                        Pay Using Trust Wallet
                                                    </a>
                                                </div>
                                            </> : <></>
                                    }

                                </> :
                                <>
                                    <Alert severity="warning" style={{ textAlign: "left" }}>
                                        <AlertTitle>Transaction Status</AlertTitle>
                                        If Payment is already done by you, <strong>Please wait for some time for confirmations.</strong>
                                    </Alert>
                                    <br />
                                    <br />
                                    <Alert severity="error" style={{ textAlign: "left" }}>
                                        <AlertTitle>Skip Payment</AlertTitle>
                                        <a href={failureUrl}>Click Here</a><strong> to leave the page without payment.</strong>
                                    </Alert>
                                </>
                        }

                        {
                            status === "Transaction Confirmed By Block Chain" ?
                                <Alert severity="success">
                                    <AlertTitle>Success</AlertTitle>
                                    Transaction Confirmed — <strong>check it out!</strong>
                                </Alert>
                                :
                                <div>
                                    <br />
                                    <br />
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        startIcon={<AutorenewIcon />}
                                    >
                                        {status}
                                    </Button>
                                    <br />
                                    <br />
                                    <Button
                                        fullWidth
                                        onClick={() => setOpen(true)}
                                        variant="outlined"
                                        color="primary"
                                    >
                                        Change Exchange
                                    </Button>
                                </div>
                        }

                    </CardContent>
                </Card>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
                <Card elevation={10}>
                    <CardContent>
                        <ul className="details">
                            <li><label>Name: </label><b>{transInfo.name}</b></li>
                            <li><label>Deposit Amount:  </label><b>{(parseFloat(amount) - parseFloat(transactionCharge)).toFixed(tokenAddress ? 4 : 8)} {tokenAddress ? tokenSymbol : "BNB"}</b></li>
                            <li><label>Transaction Charge :  </label><b>{parseFloat(transactionCharge).toFixed(tokenAddress ? 4 : 8)} {tokenAddress ? tokenSymbol : "BNB"}</b></li>
                            <li><label>Customer ID:  </label><b>{transInfo.cid}</b></li>
                            <li><label>Email:  </label><b>{transInfo.email}</b></li>
                            <li><label>Mobile No: </label><b>{transInfo.mobile}</b></li>
                            <li><label>Reference No: </label><b>{transInfo.txnid}</b></li>
                        </ul>
                        {
                            status === "Waiting For Payment" ?
                                <Timer duration={seconds} onComplete={(duration) => {
                                    setTimeLeft(duration);
                                }} /> : <span />
                        }
                    </CardContent>
                </Card>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
                <ul id="log">
                </ul>
            </Grid>
            <Dialog
                maxWidth="sm"
                fullWidth
                open={open}
                BackdropProps={{
                    style: {
                        background: "#000"
                    }
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Select Exchange</DialogTitle>
                <DialogContent>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="gender" name="gender1" value={exchange} onChange={handleChange}>
                            <FormControlLabel value="Binance Wallet" control={<Radio />} label="Binance Exchange" />
                            <FormControlLabel value="Others" control={<Radio />} label="Trust Wallet" />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button href={failureUrl} variant="contained" color="secondary" onClick={() => {

                    }}>
                        Cancel
                    </Button>
                    <Button
                        disabled={exchange === ""}
                        onClick={() => {
                            if (exchange !== "") {
                                setOpen(false);
                            }
                        }}
                        variant={exchange === "" ? "outlined" : "contained"}
                        color="primary"
                        autoFocus>
                        Proceed
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

